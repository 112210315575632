<template>
  <div>
    <v-treeview
      class="admin-manager-treeview"
      dense
      transition
      open-on-click
      :items="treeview"
      :open.sync="openItems"
      :search="'search'"
      :filter="(item) => item.hasAccess && item.hasAccess(user) && !user.blocked"
      @update:open="onChange"
    >
      <template slot="label" slot-scope="{ item }">
        <router-link
          v-if="item.path"
          :active-class="checkItemOnActive(item) ? 'active-menu-item' : ''"
          :to="item.path"
        >
          <v-list-item link @click="onMenuClick()">
            <v-list-item-icon>
              <v-icon>{{ item.icon }}</v-icon>
            </v-list-item-icon>

            <v-list-item-content>
              <v-list-item-title>{{
                $localizationService.localize(item.localizationKey)
              }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </router-link>
        <v-list-item
          v-else-if="item.id"
          :class="{
            'disabled-item': item.locked,
            'not-active-item': !item.path && item.icon,
          }"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-icon v-else-if="item.locked">
            <v-icon>mdi-menu-right</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ $localizationService.localize(item.localizationKey) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-treeview>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { getLocalStorageData, setLocalStorageData } from "../../utils/utils";

import HomePage from "../../views/HomePage.vue";
import UploadPage from "../../views/UploadPage.vue";
import CompanyManagementPage from "../../views/CompanyManagementPage.vue";
import SsePage from "../../views/SsePage.vue";
import SeatbeltPage from "../../views/SeatbeltPage.vue";
import SeatbeltVehiclePage from "../../views/SeatbeltVehiclePage.vue";
import ShiftPage from "../../views/ShiftPage.vue";
import ServiceDetailsPage from "../../views/ServiceDetailsPage.vue";
import CustomerFeedbackPage from "../../views/CustomerFeedbackPage.vue";
import CustomerFeedbackCSVPage from "@/views/CustomerFeedbackCSVPage.vue";
import FicoPage from "@/views/FicoPage.vue";
import SafetyPage from "@/views/SafetyPage.vue";
/*
import NetradyneDistractionsDriverPivot from "../../views/NetradyneDistractionsDriverPivot.vue";
import NetradyneFollowingDistanceDriverPivot from "../../views/NetradyneFollowingDistanceDriverPivot.vue";
import NetradyneSignViolationsDriverPivot from "../../views/NetradyneSignViolationsDriverPivot.vue";
 */
import FicoDistractionsDriverPivot from "../../views/FicoDistractionsDriverPivot.vue";
import FicoFollowingDistanceDriverPivot from "../../views/FicoFollowingDistanceDriverPivot.vue";
import FicoSignViolationsDriverPivot from "../../views/FicoSignViolationsDriverPivot.vue";
import DailyRoutePlannerPage from "../../views/DailyRoutePlannerPage.vue";

import { TreeviewMenuItem } from "../../model/treeview-menu-item.model";
import MessageTemplatesPage from "../../views/MessageTemplatesPage";
import PhoneAgentsPage from "../../views/PhoneAgentsPage";
import TemplateConstantsPage from "../../views/TemplateConstantsPage";
import VehiclesPage from "../../views/VehiclesPage";
import VehicleShopsPage from "../../views/VehicleShopsPage";
import VehicleGroupsPage from "../../views/VehicleGroupsPage";
import FuelCardsPage from "../../views/FuelCardsPage";
import VehicleInspectionSchemesPage from "@/views/VehicleInspectionSchemesPage";
import VehicleInspectionsPage from "../../views/VehicleInspectionsPage";
import VehicleActionTypesPage from "../../views/VehicleActionTypesPage";
import VehicleRemindersPage from "../../views/VehicleRemindersPage";
import VehicleActionsPage from "../../views/VehicleActionsPage";

export default {
  name: "AdminManagerMenu",

  data: () => ({
    openItems: [],
    localStorageName: "main.menu.treeview",
  }),

  computed: {
    ...mapState("userModule", ["user"]),

    treeview() {
      if (!this.user) return [];

      const treeview = [
        {
          localizationKey: "menu.tab.vehicles",
          children: [
            VehiclesPage,
            VehicleInspectionSchemesPage,
            VehicleInspectionsPage,
            VehicleShopsPage,
            FuelCardsPage,
            VehicleGroupsPage,
            {
              localizationKey: "menu.tab.vehicle_reminders",
              children: [
                VehicleActionTypesPage,
                VehicleRemindersPage,
                VehicleActionsPage,
              ],
            },
          ],
        },
        {
          localizationKey: "menu.tab.operations",
          children: [
            DailyRoutePlannerPage,
          ],
        },
        {
          localizationKey: "menu.tab.inventory",
          locked: true,
          children: [],
        },
        {
          localizationKey: "menu.tab.reports",
          children: [
            {
              localizationKey: "menu.tab.safety_and_compliance",
              children: [
                SeatbeltPage,
                SeatbeltVehiclePage,
                SsePage,
                /*
                {
                  localizationKey: "menu.tab.netradyne",
                  children: [
                    NetradyneDistractionsDriverPivot,
                    NetradyneSignViolationsDriverPivot,
                    NetradyneFollowingDistanceDriverPivot,
                  ],
                },
                */
                {
                  localizationKey: "menu.tab.fico",
                  children: [
                    FicoDistractionsDriverPivot,
                    FicoSignViolationsDriverPivot,
                    FicoFollowingDistanceDriverPivot,
                  ],
                },
              ],
            },
            {
              localizationKey: "menu.tab.quality_&_performance",
              children: [
                HomePage,
                CustomerFeedbackPage,
                CustomerFeedbackCSVPage,
                FicoPage,
                SafetyPage,
                ServiceDetailsPage,
                ShiftPage,
              ],
            },
          ],
        },
        {
          localizationKey: "menu.tab.settings",
          children: [
            {
              localizationKey: "menu.tab.messaging",
              children: [
                MessageTemplatesPage,
                PhoneAgentsPage,
                TemplateConstantsPage,
              ],
            },
            CompanyManagementPage,
            UploadPage,
            {
              routing: {
                localizationKey: "menu.tab.other_settings",
                icon: "mdi-wrench",
                path: "",
                hasAccess: (user) => user.isAdmin || user.isManager,
              },
            },
          ],
        },
      ];
      return treeview.map((it, index) => TreeviewMenuItem.toModel(it, index + 1));
    },
  },

  methods: {
    checkItemOnActive(item) {
      return this.$route.path === "/"
        || (
          this.$route.path.startsWith(item.path)
          && item.path !== "/"
        );
    },

    onChange(items) {
      setTimeout(() => {
        setLocalStorageData(this.localStorageName, items);
      });
    },

    onMenuClick() {
      this.$emit("onMenuClick");
    },
  },

  mounted() {
    this.openItems = (getLocalStorageData(this.localStorageName) || []).map((item) => +item);
  },
};
</script>

<style lang="scss">
.admin-manager-treeview {
  a {
    color: transparent !important;
  }
}
</style>
