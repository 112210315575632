export class TreeviewMenuItem {
  constructor(id, localizationKey, locked, icon, path, hasAccess) {
    this.id = id;
    this.localizationKey = localizationKey;
    this.icon = icon;
    this.path = path;
    this.hasAccess = hasAccess;
    this.locked = locked;
  }

  static toModel({
    localizationKey, routing, locked, children,
  }, parentId, index) {
    const menuItem = new TreeviewMenuItem(
      // 1
      //   -> 11
      //        -> 111
      //        -> 112
      //   -> 12
      //        -> 121
      index ? +`${parentId}${index}` : parentId,
      localizationKey || routing.localizationKey,
      locked,
      (routing || {}).icon,
      (routing || {}).path,
      (routing || {}).hasAccess,
    );

    if (children) {
      menuItem.children = children.map((it, ind) => TreeviewMenuItem.toModel(it, menuItem.id, ind + 1));
    }

    return menuItem;
  }
}
